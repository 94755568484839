<template lang="pug">
  div
    v-data-table(
      :items="candledRackEggIndexList"
      :headers="headers"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :server-items-length="candledRackEggIndexGetCount"
      :loading="candledRackEggIndexGetting"
    )
      template(v-slot:top)
        v-row
          v-col(cols="12" lg="6" md="6")
            v-text-field(
              label="search"
              append-icon="mdi-magnify"
              v-model="search"
            )
          v-col(cols="12" lg="6" md="6")
            v-text-field(
              label="date"
              type="date"
              v-model="date"
            )
      template(v-slot:item.date="{ value }")
        span {{ value.dateFormat() }}
      template(v-slot:item.rack_egg_id="{ item }")
        span {{ item.rack_code.code }}
        v-icon(
          v-if="accessLevel > 5"
          color="yellow darken-3"
          @click="$emit('edit', item)"
        ) mdi-pencil
</template>
<script>
import candledRackEggRepository from '@/repositories/candled-rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [candledRackEggIndexVars, candledRackEggIndexVarNames] = requestVars({ identifier: 'candled-rack-egg-index', pagination: true })

const candledRackEggIndexHandler = new VueRequestHandler(null, candledRackEggIndexVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'Date', value: 'date' },
    { text: 'Rack No.', value: 'rack_egg_id' },
    { text: 'Good', value: 'good' },
    { text: 'Bad', value: 'bad' },
    { text: 'Harvested', value: 'harvested' },
    { text: 'Infertile', value: 'infertile' },
    { text: 'Dead Embryo', value: 'dead_embryo' },
  ],
  page: 1,
  itemsPerPage: 10,
  search: null,
  date: null,
})

export default {
  name: 'TableCandledRackEggs',
  data () {
    return {
      ...tableVars(),
      ...candledRackEggIndexVars,
    }
  },
  computed: {
    candledRackEggIndexParams () {
      return {
        page: this.page,
        limit: this.itemsPerPage,
        search: this.search,
        date: this.date,
      }
    },
    accessLevel () {
      return this.$store.state.auth.accessLevel
    },
  },
  created () {
    this.getCandledRackEggs()
    this.initWebsocket()
  },
  watch: {
    search () {
      this.page = 1
    },
    date () {
      this.page = 1
    },
    candledRackEggIndexParams () {
      this.getCandledRackEggs()
    },
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = ({ data, model }) => {
        if (model === 'CandledRackEgg') {
          this.candledRackEggIndexList.pop()
          this.candledRackEggIndexList.unshift(data)
          this.candledRackEggIndexGetCount++
          this.$emit('updated')
        }
      }
      const dbUpdateEvent = ({ data, model }) => {
        if (model === 'CandledRackEgg') {
          this.candledRackEggIndexList = this.candledRackEggIndexList.map(item => {
            if (item.id === data.id) return Object.assign({ ...item, ...data })
            return Object.assign({ ...item })
          })
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
        .listen('DBUpdateEvent', dbUpdateEvent)
    },
    getCandledRackEggs () {
      const handler = candledRackEggIndexHandler
      const repository = candledRackEggRepository.index
      const params = this.candledRackEggIndexParams
      handler.setVue(this)
      handler.execute(repository, [params])
    },
  },
}
</script>